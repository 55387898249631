import React, { Component, ErrorInfo, ReactNode } from 'react';
import ErrorPageLayout from './ErrorPageLayout';
import ErrorPageVisual from './ErrorPageVisual';
import log from '@howl/shared-utils/misc/logger';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
}

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: Error): State {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    log.info('Uncaught error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorPageLayout>
          <ErrorPageVisual error={this.state.error!} />
        </ErrorPageLayout>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
